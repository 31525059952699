import { createAsync, Navigate } from '@solidjs/router';
import { Heading } from '@troon/ui';
import { Show } from 'solid-js';
import { IconGolfCartCustom } from '@troon/icons/golf-cart-custom';
import { requireLoggedIn, useOperatorFacilities } from '../../providers/root';

export default function Dashboard() {
	const user = createAsync(() => requireLoggedIn());
	const facilities = useOperatorFacilities();

	return (
		<Show when={facilities()}>
			{(facilities) => (
				<Show
					when={(facilities().length ?? 0) !== 1}
					fallback={<Navigate href={`/facility/${facilities()[0]!.facility.slug}`} />}
				>
					<div class="flex grow flex-col items-center justify-center gap-4">
						<IconGolfCartCustom class="size-48 text-brand" />
						<Heading as="h1">Hello, {user()?.firstName}</Heading>
						<p>Please select your facility to continue.</p>
					</div>
				</Show>
			)}
		</Show>
	);
}
